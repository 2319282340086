import {Component, Inject, OnDestroy, OnInit, signal} from '@angular/core'
import {AssignmentIndicator, PostRequest, Updates} from '../../../store/assignment-indicators-store/actions'
import {Store} from '@ngrx/store'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {
  ASSIGNMENT_INDICATOR_DESCRIPTION_MAX_LENGTH,
  ASSIGNMENT_INDICATOR_NAME_MAX_LENGTH,
  ServerConstantsService
} from '../../../services/server-constants.service'
import {Subscription} from 'rxjs'
import {Actions, ofType} from "@ngrx/effects";
import {take} from "rxjs/operators";

const DEFAULT_INPUT_LENGTH = 200

@Component({
  selector: 'app-assignment-indicator-edit-dialog',
  templateUrl: './assignment-indicator-edit-dialog.component.html',
  styleUrl: './assignment-indicator-edit-dialog.component.scss'
})
export class AssignmentIndicatorEditDialogComponent implements OnInit, OnDestroy {

  indicatorNameMaxLength: number
  indicatorDescriptionMaxLength: number

  private serverConstantsSubscription: Subscription


  assignmentIndicator = signal<AssignmentIndicator>(
    {
      id: null,
      name: '',
      description: '',
      created: null,
      archived: false,
    }
  )

  constructor(private store: Store,
              private dialogRef: MatDialogRef<AssignmentIndicatorEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssignmentIndicator,
              private serverConstantsService: ServerConstantsService,
              private actions$: Actions,
  ) {
    if (data) {
      this.assignmentIndicator.set(data)
    }
  }

  ngOnInit(): void {
    this.serverConstantsSubscription = this.serverConstantsService.onConstantsExtracted$.subscribe(constants => {
      this.indicatorNameMaxLength = constants[ASSIGNMENT_INDICATOR_NAME_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
      this.indicatorDescriptionMaxLength = constants[ASSIGNMENT_INDICATOR_DESCRIPTION_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
    })
  }

  confirm(): void {
    this.store.dispatch(PostRequest({
      upserts: [this.assignmentIndicator()],
      deletes: [],
    }))

    this.actions$.pipe(
      ofType(Updates),  // Warten auf die Updates-Action
      take(1)           // Nur einmal reagieren, dann automatisch beenden
    ).subscribe(() => this.dialogRef.close())

  }

  updateName(newName: string): void {
    this.assignmentIndicator.set({
      ...this.assignmentIndicator(),
      name: newName,
    })
  }

  updateDescription(newDescription: string): void {
    this.assignmentIndicator.set({
      ...this.assignmentIndicator(),
      description: newDescription,
    })
  }

  cancel(): void {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.serverConstantsSubscription.unsubscribe()
  }


}

<div class="filter-header">
  <div class="filter-header-item"></div>
  <div class="filter">
    <input #input
           [formControl]="inputControl"
           class="filter-input"
           matInput
           maxlength="1000"
           placeholder="Filtern nach..."
           type="text">
    <button mat-mini-fab>
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
  <div class="filter-header-item">
  </div>
</div>

<div class="sortable-table-container">
  <app-sortable-table
    [columnDefinitions]="columns"
    [columnSizes]="['15%', '15%', '15%', '10%', '10%', '15%','15%', '10%']"
    [data]="tableData"
    [filterPredicate]="filterFunction"
    [filter]="filter"
    key="user-editor-table-page-size">

    <ng-template #Mitarbeiter let-data>
      <span [title]="data.name">
        {{data.name}}
      </span>
    </ng-template>

    <ng-template #Dienstleister let-data>
      <span [title]="data.companyName">{{data.companyName}}</span>
    </ng-template>

    <ng-template #KontaktEmail let-data>
      <span [title]="data.email">{{data.email}}</span>
    </ng-template>

    <ng-template #Start let-data>
      <span [title]="data.workStart | formatDate">
        {{data.workStart | formatDate}}
      </span>
    </ng-template>

    <ng-template #Nachweis let-data>
      <div [title]="data.timeSheetStatus| statusDisplayName" class="status-container">
        <div [class.active]="isTimeSheetRequired(data)" [class.anonymized]="isTimeSheetOptional(data)"
             class="circle"></div>
        <span>{{data.timeSheetStatus | statusDisplayName}}</span>
      </div>
    </ng-template>

    <ng-template #SaldoGesamt let-data>
      <div class="balance-container">
        <span [ngClass]="data.balance | balanceColor">
          {{ data.balance | balanceFormat }}
        </span>
        <button class="info-button" mat-mini-fab (click)="openInfoDialog(data)">
          <mat-icon color="primary">info_outline</mat-icon>
        </button>
      </div>
    </ng-template>


    <ng-template #Verantwortlicher let-data>
      <span>{{data.supervisorName}}</span>
    </ng-template>

    <ng-template #Bearbeiten let-data>
      <div class="action-container">
        <button (click)="editUser(data)" class="action-button">Bearbeiten</button>
      </div>
    </ng-template>

  </app-sortable-table>
</div>

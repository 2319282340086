<div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Projektkennzeichen</mat-label>
      <input
        [value]="assignmentIndicator().name"
        [maxLength]="indicatorNameMaxLength"
        (input)="updateName($event.target.value)"
        matInput
        placeholder="Name">
      <mat-hint align="end">{{ assignmentIndicator().name.length }}/{{ indicatorNameMaxLength }}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input
        [value]="assignmentIndicator().description"
        [maxLength]="indicatorDescriptionMaxLength"
        (input)="updateDescription($event.target.value)"
        matInput
        placeholder="Beschreibung">
      <mat-hint align="end">{{ assignmentIndicator().description.length }}/{{ indicatorDescriptionMaxLength }}</mat-hint>
    </mat-form-field>
  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>Speichern</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>


import {Injectable} from '@angular/core'
import {
  isComplexSqlType,
  RemoteConfigurationService,
  SqlType,
  TableSpec
} from './repository/remote-configuration.service'
import {BehaviorSubject, Observable} from 'rxjs'

export const TIME_ENTRY_TYPES_NAME_MAX_LENGTH = 'TimeEntryTypesNameMaxLength'
export const TIME_ENTRY_TYPES_DESCRIPTION_MAX_LENGTH = 'TimeEntryTypesDescriptionMaxLength'

export const ABSENCE_TYPES_NAME_MAX_LENGTH = 'TimeEntryTypesNameMaxLength'
export const ABSENCE_TYPES_DESCRIPTION_MAX_LENGTH = 'TimeEntryTypesDescriptionMaxLength'

export const CUSTOMERS_NAME_MAX_LENGTH = 'CustomersNameMaxLength'
export const CUSTOMERS_DESCRIPTION_MAX_LENGTH = 'CustomersDescriptionMaxLength'

export const ASSIGNMENTS_NAME_MAX_LENGTH = 'AssignmentsNameMaxLength'
export const ASSIGNMENTS_DESCRIPTION_MAX_LENGTH = 'AssignmentsDescriptionMaxLength'
export const ASSIGNMENTS_CONTRACT_NUMBER_MAX_LENGTH = 'AssignmentsContractNumberMaxLength'

export const TOPICS_NAME_MAX_LENGTH = 'TopicsNameMaxLength'
export const TOPICS_DESCRIPTION_MAX_LENGTH = 'TopicsDescriptionMaxLength'

export const ASSIGNMENT_INDICATOR_NAME_MAX_LENGTH = 'AssignmentIndicatorNameMaxLength'
export const ASSIGNMENT_INDICATOR_DESCRIPTION_MAX_LENGTH = 'AssignmentIndicatorDescriptionMaxLength'

type SqlValue = string | number

interface Entry {
  key: string,
  value: SqlValue
}

type ExtractorFunction = (TableSpec) => Entry[]

@Injectable({
  providedIn: 'root'
})
export class ServerConstantsService {

  private readonly tableExtractors = {
    'timeentries': extractTimeEntriesConstants,
    'timeentrytypes': extractTimeEntryTypesConstants,
    'absenceentries': extractAbsenceEntriesConstants,
    'absencetypes': extractAbsenceEntryTypesConstants,
    'users': extractUsersConstants,
    'topics': extractTopicsConstants,
    'assignments': extractAssignmentsConstants,
    'customers': extractCustomersConstants,
    'assignment_indicator': extractAssignmentIndicatorConstants
  }

  /* Object with key value pairs */
  private constants = {}

  constructor(private remoteConfigurationService: RemoteConfigurationService) {
    this.remoteConfigurationService.tableSpecs$.subscribe((tableSpecs) => {
      this.extractConstants(tableSpecs)
    })
  }

  private _onConstantsExtracted$ = new BehaviorSubject<any>({})

  get onConstantsExtracted$(): Observable<void> {
    return this._onConstantsExtracted$
  }

  getConstant<T extends SqlValue>(key: string) {
    return this.constants[key] as T
  }

  private extractConstants(tableSpecs: TableSpec[]) {
    if (tableSpecs != null) {
      tableSpecs.forEach((tableSpec) => {
        const extractorFunction: ExtractorFunction = this.tableExtractors[tableSpec.name]
        if (extractorFunction === undefined) {
          console.warn(`Missing extractor function for table: ${tableSpec.name}`)
        } else {
          const extractorResult = extractorFunction(tableSpec)
          extractorResult.forEach(entry => {
            this.constants[entry.key] = entry.value
          })
        }
      })

      this._onConstantsExtracted$.next(this.constants)
    }
  }
}

function extractTimeEntriesConstants(tableSpec: TableSpec): Entry[] {
  return []
}

function extractAbsenceEntriesConstants(tableSpec: TableSpec): Entry[] {
  return []
}

function extractTimeEntryTypesConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')

  return [
    createEntryFromComplexSqlTypeMaxLength(TIME_ENTRY_TYPES_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(TIME_ENTRY_TYPES_DESCRIPTION_MAX_LENGTH, description)
  ].filter(entry => entry != null)
}

function extractAbsenceEntryTypesConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')

  return [
    createEntryFromComplexSqlTypeMaxLength(ABSENCE_TYPES_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(ABSENCE_TYPES_DESCRIPTION_MAX_LENGTH, description)
  ].filter(entry => entry != null)
}

function extractUsersConstants(tableSpec: TableSpec): Entry[] {
  return []
}

function extractTopicsConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')

  return [
    createEntryFromComplexSqlTypeMaxLength(TOPICS_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(TOPICS_DESCRIPTION_MAX_LENGTH, description)
  ].filter(entry => entry != null)
}

function extractAssignmentsConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')
  const contractNumber = tableSpec.columns.get('contractNumber')

  return [
    createEntryFromComplexSqlTypeMaxLength(ASSIGNMENTS_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(ASSIGNMENTS_DESCRIPTION_MAX_LENGTH, description),
    createEntryFromComplexSqlTypeMaxLength(ASSIGNMENTS_CONTRACT_NUMBER_MAX_LENGTH, contractNumber)
  ].filter(entry => entry != null)
}

function extractAssignmentIndicatorConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')

  return [
    createEntryFromComplexSqlTypeMaxLength(ASSIGNMENT_INDICATOR_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(ASSIGNMENT_INDICATOR_DESCRIPTION_MAX_LENGTH, description),
  ].filter(entry => entry != null)
}

function extractCustomersConstants(tableSpec: TableSpec): Entry[] {
  const name = tableSpec.columns.get('name')
  const description = tableSpec.columns.get('description')

  return [
    createEntryFromComplexSqlTypeMaxLength(CUSTOMERS_NAME_MAX_LENGTH, name),
    createEntryFromComplexSqlTypeMaxLength(CUSTOMERS_DESCRIPTION_MAX_LENGTH, description)
  ].filter(entry => entry != null)
}

function createEntryFromComplexSqlTypeMaxLength(key: string, sqlType: SqlType): Entry {
  if (sqlType != null && isComplexSqlType(sqlType)) {
    return {
      key,
      value: sqlType.maxLength
    }
  }

  return undefined
}

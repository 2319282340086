import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {DialogReturnType} from '../../shared-components/dialog-return-type'
import {
  hasPermissions,
  mapSortStateToNumber,
  Permission,
  TimeSheetStatus,
  TimeSheetStatusNames,
  User,
  UserEntry,
  UserService,
  UserStatus
} from '../../services/repository/user.service'
import {UsersAnonDialogComponent} from '../users-anon-dialog/users-anon-dialog.component'
import {of, Subject, takeUntil} from 'rxjs'
import {Month, SubmitMonthService, SubmitType} from '../../services/repository/submit-month.service'
import {Workplaces, WorkplacesService, WorkplacesTableDate} from '../../services/repository/workplaces.service'
import {AddWorkplaceDialogComponent} from '../add-workplace-dialog/add-workplace-dialog.component'
import {DateUtils} from '../../util/date-utils'
import {SelectOption} from '../../shared-components/select/select.component'
import {HttpClient} from '@angular/common/http'
import {catchError} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {TimeTrackerService} from '../../services/time-tracker.service'

const desiredSubmitTypes: SubmitType[] = [
  SubmitType.Open,
  SubmitType.Reopened,
  SubmitType.Submitted
]

@Component({
  selector: 'app-users-edit-dialog',
  templateUrl: './users-edit-dialog.component.html',
  styleUrls: ['./users-edit-dialog.component.scss']
})
export class UsersEditDialogComponent implements OnInit, OnDestroy {
  repoURL: string
  timeSheetStatusOptions: SelectOption<TimeSheetStatus>[] = []

  supervisorOptions: SelectOption<User>[] = []
  names = TimeSheetStatusNames
  user: UserEntry
  type: UsersEditDialogType
  canEditEmail = false

  workplacesDialogData: WorkplacesTableDate[]
  initialWorkplaces: Workplaces[]
  countries: SelectOption<string>[]
  counties: Map<string, SelectOption<string>[]>
  noWorkplaceSelected = false

  addWorkplaceDialog = AddWorkplaceDialogComponent

  private destroy$ = new Subject<void>()

  availableMonths: SelectOption<number>[] = []
  availableYears: SelectOption<number>[] = []

  selectedMonth: number | null = null
  selectedYear: number | null = null

  _initalSaldocorrection = {
    saldo: '00:00',
    month: null as number | null,
    year: null as number | null,
    comment: '',
    remove40hCap: false
  }
  correction = {...this._initalSaldocorrection}

  saldoError = false
  saldoErrorMessage = ''
  submittedMonths: Month[] = []
  balanceChanged = false
  initialRemove40hCap = false

  constructor(
    private dialogRef: MatDialogRef<UsersEditDialogComponent>,
    private dialog: MatDialog,
    private userService: UserService,
    private submitMonthService: SubmitMonthService,
    private workplacesService: WorkplacesService,
    private httpClient: HttpClient,
    private timeTrackerService: TimeTrackerService,
    @Inject(MAT_DIALOG_DATA) public data: UsersEditDialogData
  ) {
    this.repoURL = `${environment.serverUrl}/eventstore`
  }

  ngOnInit(): void {
    this.timeSheetStatusOptions = Object.keys(TimeSheetStatus).map(value => {
      return {
        displayName: TimeSheetStatusNames[value],
        value: TimeSheetStatus[value]
      }
    })

    this.user = {...this.data.user}
    this.type = this.data.type

    this.submitMonthService.getSubmitMonthsOfUser(this.user.id, desiredSubmitTypes).subscribe(
      (months: Month[]) => {
        this.processMonths(months)
        this.setDefaultSelections()
        this.fetchLastCapState()
      },
      (error) => {
        console.error('Error fetching submitted months:', error)
      }
    )

    this.loadSubmittedMonths()

    this.userService.allSupervisor$
      .pipe(takeUntil(this.destroy$))
      .subscribe(supervisors => {
        this.supervisorOptions = supervisors.map(supervisor => {
          return {
            typeId: 0,
            displayName: `${supervisor.lastName}, ${supervisor.firstName}`,
            value: supervisor
          }
        })
      })

    this.dialogRef.updatePosition({top: '10vh'})
    this.checkChangeContactEmailPermission()

    if (this.type === UsersEditDialogType.BaseData) {
      this.workplacesService
        .workplacesById(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.initializeWorkplaceArrays(data)
          },
          error: error => console.error('Error fetching workplaces by id', error)
        })
    } else {
      this.workplacesService
        .myWorkplaces()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.initializeWorkplaceArrays(data)
          },
          error: error => console.error('Error fetching users workplaces', error)
        })
    }

    this.countries = [...this.workplacesService.getCountryMapping().values()].map(country => {
      return {
        displayName: this.getCountry(country),
        value: country
      }
    })
    this.setCounties()
  }

  hasCorrectBalancePermission(): boolean {
    return hasPermissions(this.userService.me, [Permission.CorrectBalancePermission])
  }

  processMonths(months: Month[]): void {
    const monthMap: Map<number, string> = new Map()
    const yearSet: Set<number> = new Set()

    months.forEach(monthEntry => {
      const date = new Date(monthEntry.month)
      const monthNumber = date.getMonth() + 1
      const monthName = this.getMonthName(monthNumber)
      const year = date.getFullYear()

      monthMap.set(monthNumber, monthName)
      yearSet.add(year)
    })

    this.availableMonths = Array.from(monthMap.entries())
      .map(([number, name]) => ({
        displayName: name,
        value: number,
        disabled: false
      }))
      .sort((a, b) => a.value - b.value)

    this.availableYears = Array.from(yearSet)
      .map(year => ({
        displayName: year.toString(),
        value: year,
        disabled: false
      }))
      .sort((a, b) => b.value - a.value)
  }

  private getMonthName(monthNumber: number): string {
    const monthNames = [
      'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
      'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ]
    return monthNames[monthNumber - 1]
  }

  onMonthOrYearChange(): void {
    this.fetchLastCapState()
    this.markSubmittedMonthsAsDisabled()
    this.validateSaldo()
  }

  parseTimeToSeconds(timeString: string): number {
    if (!timeString || typeof timeString !== 'string') {
      return 0;
    }

    const match = timeString.match(/^(-?)(\d+):([0-5]?\d)$/);
    if (!match) {
      return 0;
    }

    const isNegative = match[1] === "-";
    const hours = parseInt(match[2], 10);
    const minutes = parseInt(match[3], 10);

    let totalSeconds = (hours * 3600) + (minutes * 60);
    return isNegative ? -totalSeconds : totalSeconds;
  }

  loadSubmittedMonths(): void {
    this.submitMonthService.getSubmitMonthsOfUser(this.user.id, [SubmitType.Submitted]).subscribe(
      (months: Month[]) => {
        this.submittedMonths = months
        this.markSubmittedMonthsAsDisabled()
      },
      (error) => {
        console.error('Error fetching submitted months', error)
      }
    )
  }

  markSubmittedMonthsAsDisabled(): void {
    if (!this.correction.year) {
      return
    }

    const submittedMonthSet = new Set<string>(
      this.submittedMonths
        .filter(submittedMonth => submittedMonth.type === SubmitType.Submitted)
        .map(submittedMonth => submittedMonth.month.substring(0, 7))
    )

    this.availableMonths.forEach(monthOption => {
      const monthNumber = monthOption.value
      const monthValue = monthNumber.toString().padStart(2, '0')
      const dateString = `${this.correction.year}-${monthValue}`

      monthOption.disabled = submittedMonthSet.has(dateString)
    })
  }

  onBalanceChange(): void {
    this.balanceChanged = true
  }

  private fetchLastCapState(): void {
    if (!this.user.id || !this.correction.month || !this.correction.year) {
      return
    }

    const month = this.correction.month
    const year = this.correction.year
    this.httpClient.get<CapStateResponse>(`${this.repoURL}/saldo/capState/${this.user.id}?month=${month}&year=${year}`).subscribe({
      next: (response: CapStateResponse) => {
        this.correction.remove40hCap = response
        this._initalSaldocorrection = {...this.correction}
        this.initialRemove40hCap = response
      },
      error: (error) => {
        console.error('Error fetching cap state:', error)
      }
    })
  }

  validateSaldo(): void {
    const submittedMonth = this.submittedMonths.find(month => {
        return month.month === `${this.correction.year}-${String(this.correction.month).padStart(2, '0')}-01`
      }
    )

    if (submittedMonth && (submittedMonth.type !== SubmitType.Open
      && submittedMonth.type !== SubmitType.Reopened)) {
      this.saldoError = true
      this.saldoErrorMessage = `Der Monat ist schon ${submittedMonth.type}`
      return
    }


    const input = this.correction.saldo.trim()

    const saldoPattern = /^-?\d+:[0-5]\d$/

    if (input === '') {
      this.correction.saldo = '00:00'
      this.saldoError = false
      this.saldoErrorMessage = ''
    } else if (saldoPattern.test(input)) {
      this.correction.saldo = this.formatSaldo(input)
      this.saldoError = false
      this.saldoErrorMessage = ''
    } else {
      const isNegative = /^-?\d+$/.test(input)
      if (isNegative) {
        let hours = input.replace('-', '')
        const sign = input.startsWith('-') ? '-' : ''

        if (hours.length === 1) {
          hours = '0' + hours
        }
        this.correction.saldo = `${sign}${hours}:00`

        if (saldoPattern.test(this.correction.saldo)) {
          this.saldoError = false
          this.saldoErrorMessage = ''
        } else {
          this.saldoError = true
          this.saldoErrorMessage = 'Bitte geben Sie die Zeit im Format HH:MM ein.'
        }
      } else {
        this.saldoError = true
        this.saldoErrorMessage = 'Bitte geben Sie die Zeit im Format HH:MM ein.'
      }
    }

    this.balanceChanged = true
  }

  adjustSaldo(amount: number): void {
    const saldoStr = this.correction.saldo
    let isNegative = false
    let timeParts = saldoStr

    if (saldoStr.startsWith('-')) {
      isNegative = true
      timeParts = saldoStr.substring(1)
    }

    const [hoursStr, minutesStr] = timeParts.split(':')
    const hours = parseInt(hoursStr, 10)
    const minutes = parseInt(minutesStr, 10)

    let totalMinutes = hours * 60 + minutes
    if (isNegative) {
      totalMinutes = -totalMinutes
    }

    totalMinutes += amount * 15

    if (totalMinutes < 0) {
      isNegative = true
      totalMinutes = -totalMinutes
    } else {
      isNegative = false
    }

    const newHours = Math.floor(totalMinutes / 60)
    const newMinutes = totalMinutes % 60

    this.correction.saldo = `${isNegative ? '-' : ''}${newHours.toString().padStart(2, '0')}:${newMinutes
      .toString()
      .padStart(2, '0')}`
  }

  formatSaldo(input: string): string {
    let [hours, minutes] = input.split(':')

    if (hours.length === 1) {
      hours = '0' + hours
    }

    return `${hours}:${minutes}`
  }

  getCountry(key: string): string {
    return this.workplacesService.getCountry(key)
  }

  getCounty(key: string, country: string): string {
    return this.workplacesService.getCounty(key, country)
  }

  initializeWorkplaceArrays(data: Workplaces[]): void {
    const currentAndFutureWorkplaces = this.updateWorkplaces(data)
    this.workplacesDialogData = currentAndFutureWorkplaces.map(entry => {
      return {
        ...entry,
      }
    })
    this.initialWorkplaces = data.map(entry => {
      return {
        ...entry,
      }
    })
  }

  filteredFormData(country: string): string[] {
    if (!country) {
      return []
    }
    return [...this.workplacesService.getCountyMapping(country).values()]
  }

  getCounties(country: string): SelectOption<string>[] {
    if (!country) {
      return []
    }
    return [...this.workplacesService.getCountyMapping(country).values()].map(county => {
      return {
        displayName: this.getCounty(county, country),
        value: county,
      }
    })
  }

  setCounties(): void {
    this.counties = new Map(
      Array.from(this.workplacesService.getCountyMappingForAll()).map(([key, value]) => {
        return [
          key,
          Array.from(value).map(([key1, value1]) => {
            return {
              displayName: key1,
              value: value1
            }
          })
        ]
      })
    )
  }

  formatDate(date: string): string {
    return DateUtils.formatDate(DateUtils.stringToDate(date))
  }

  onCountryChange(data: WorkplacesTableDate): void {
    data.county = ''
    this.validateWorkplaces()
  }

  updateWorkplaces(workplaces: WorkplacesTableDate[]): WorkplacesTableDate[] {
    workplaces = workplaces.map(workplace => {
      return {
        ...workplace,
        currentWorkplace: null,
        futureWorkplace: null,
      }
    })
    if (workplaces.length > 1) {
      for (let i = workplaces.length - 1; i >= 0; i--) {
        const date = DateUtils.stringToDate(workplaces[i].startDate)
        const isFirstDateBeforeOrToday = DateUtils.isBeforeDate(date, DateUtils.today) || DateUtils.isToday(date)
        if (isFirstDateBeforeOrToday) {
          workplaces[i].currentWorkplace = true
          break
        }
        workplaces[i].futureWorkplace = true
      }
    } else {
      workplaces[0].currentWorkplace = true
    }
    return workplaces
  }

  countFutureWorkplaces(): boolean {
    return !this.workplacesDialogData?.some(workplace => workplace.futureWorkplace)
  }

  onAfterAddWorkplaceDialogClosed(result: any): void {
    if (result != null) {
      if (result.dialogAction === DialogReturnType.SAVE) {
        const current = this.workplacesDialogData.filter(workplace => workplace.currentWorkplace)
        const date1 = DateUtils.stringToDate(result.workplace.startDate)
        const date2 = DateUtils.stringToDate(current[0].startDate)
        if (DateUtils.isBeforeDate(date1, date2)) {
          const workplace = {
            ...result.workplace,
            futureWorkplace: true,
          } as WorkplacesTableDate
          this.workplacesDialogData.push(workplace)
        } else {
          this.workplacesDialogData.push(result.workplace as WorkplacesTableDate)
          this.workplacesDialogData = this.updateWorkplaces(this.workplacesDialogData)
        }
      }
    }
  }

  delete(data: WorkplacesTableDate): void {
    this.workplacesDialogData = this.workplacesDialogData.filter(workPlace => workPlace !== data)
    this.validateWorkplaces()
  }

  validateWorkplaces(): void {
    this.noWorkplaceSelected = this.workplacesDialogData.some(data => data.county === '' || data.country === '')
  }

  cancel(): void {
    this.dialogRef.close({
      result: DialogReturnType.CANCEL
    })
  }

  send(): void {
    this.validateWorkplaces()
    this.validateSaldo()

    if (!this.noWorkplaceSelected && !this.saldoError) {
      if (
        this.hasCorrectBalancePermission()
        && JSON.stringify(this.correction) !== JSON.stringify(this._initalSaldocorrection)
      ) {

        const payload = {
          userId: this.user.id,
          saldoChange: this.parseTimeToSeconds(this.correction.saldo) || 0,
          month: this.correction.month || 0,
          year: this.correction.year || new Date().getFullYear(),
          comment: this.correction.comment || '',
          remove40hCap: !!this.correction.remove40hCap
        }

        this.httpClient.post(`${this.repoURL}/saldo/corrections`, payload, {responseType: 'json'}).pipe(
          catchError(err => {
            console.error('Failed to send correction data', err)
            return of(null)
          })
        ).subscribe({
          next: (response: any) => {
            if (response) {
              this.timeTrackerService.triggerBalanceCorrection()
              this.dialogRef.close({
                dialogAction: DialogReturnType.SAVE,
                userData: this.user,
                workplaceData: this.workplacesDialogData,
                initialWorkPlaces: this.initialWorkplaces,
              })
            }
          },
          error: (error) => {
            console.error('Error in request:', error)
          }
        })
      } else {
        this.dialogRef.close({
          dialogAction: DialogReturnType.SAVE,
          userData: this.user,
          workplaceData: this.workplacesDialogData,
          initialWorkPlaces: this.initialWorkplaces,
        })
      }
    }
  }

  anonymizeUser(userEntry: UserEntry): void {
    this.submitMonthService.getNumberOfMonthSubmittedBySubmitTypes(
      [SubmitType.Open, SubmitType.Reopened, SubmitType.Submitted], userEntry.id)
      .then((data) => {
        const anonPossible = data < 1
        const dialog = this.dialog.open(UsersAnonDialogComponent, {
          data: {name: userEntry.name, anonPossible},
          autoFocus: anonPossible,
        })
        dialog.afterClosed().subscribe(result => {
          if (result?.dialogAction === DialogReturnType.SAVE) {
            userEntry.status = UserStatus.ANONYM
            userEntry.timeSheetStatus = TimeSheetStatus.OPTIONAL
            userEntry.timeSheetStatusSort = mapSortStateToNumber(userEntry.timeSheetStatus)
            const foundUser = this.userService.all.find(user => user.id === userEntry.id)
            if (foundUser !== undefined) {
              this.userService.anonymizeUser(foundUser)
            }
            this.dialogRef.close({
              result: DialogReturnType.DELETE
            })
          }
        })
      })
      .catch(error => {
        console.error('Error fetching number of submitted months:', error)
      })
  }

  public isSupervisorEquals(thisSupervisor: User, otherSupervisor: User): boolean {
    return (thisSupervisor != null && otherSupervisor != null) && (thisSupervisor.id === otherSupervisor.id)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private checkChangeContactEmailPermission(): void {
    if (this.type === UsersEditDialogType.BaseData) {
      this.userService.getPermissionsOfUser(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(permissions => {
          this.canEditEmail = permissions.some(permission => permission === Permission.ChangeContactEmailPermission)
        })
    } else {
      this.canEditEmail = this.user.permissions.some(permission => permission === Permission.ChangeContactEmailPermission)
    }
  }

  setDefaultSelections(): void {
    const today = new Date()
    this.correction.month = today.getMonth() + 1
    this.correction.year = today.getFullYear()
    this.selectedMonth = this.correction.month
    this.selectedYear = this.correction.year

    this._initalSaldocorrection = {...this.correction}

    this.markSubmittedMonthsAsDisabled()
  }
}

export interface UsersEditDialogData {
  user: UserEntry
  type: UsersEditDialogType
}

export enum UsersEditDialogType {
  Snackbar,
  BaseData
}

type CapStateResponse = boolean
